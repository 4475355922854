"use client";

import { useEffect } from "react";

import Button from "@/components/common/PrimaryButton";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      userAgent: navigator.userAgent,
      url: window.location.href,
    };

    const reqBody = {
      content: JSON.stringify(errorDetails),
    };

    fetch(
      "https://discord.com/api/webhooks/1330040071797346364/wSKNhOd_5tg6EItFh8691fU-0KvRQOw5TMJAJZQfcXuJHdGKy_hMdAFLbdOAWPy8__ls",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      }
    ).catch((err) => {
      console.error("Failed to send error report:", err);
    });
  }, [error]);

  return (
    <html>
      <body>
        <div
          style={{
            paddingTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            height: "100svh",
            width: "100vw",
            color: "#000000",
            background: "#ffffff",
          }}
        >
          <h1>Something went wrong!</h1>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Button variant="outlined" onClick={() => handleRefresh()}>
              Try again
            </Button>
            <a
              href={`${process.env.NEXT_PUBLIC_BASE_URL}/`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="secondary">Go Home</Button>
            </a>
          </div>
        </div>
      </body>
    </html>
  );
}
